/*
 * File: Journal.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: August 3, 2024 at 11:25 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

// Utilities
import { formatDateString } from '../../../utilities/dateTime';

// Slices
import { fetchJournalEntries, fetchJournalPrompts } from '../../store/slices/journal/journal.slice';

// Components
import { Typography } from '../Typography';
import { Button } from '../Button';
import { JournalEntryWidget } from '../JournalEntryWidget';
import { ErrorComponent } from '../ErrorComponent';
import { Spinner } from '../Spinner';
import { JournalResponseWidget } from '../JournalResponseWidget';

// Constants
import { ROLES } from '../../../Constants';

// Styles
import * as S from './Journal.styles';


/**
 * Component
 */

export const Journal = ({
	className, onCompleteAction, onEntryCreated, onResponseCreated, showEmptyState
}) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Set state
	const [isJournalEntryOpen, setJournalEntryOpen] = useState(false);
	const [expandedState, setExpandedState] = useState({});

	// Get journal from hook
	const journalEntries = useSelector((state) => state.journal.entries.value);
	const journalPrompts = useSelector((state) => state.journal.prompts.value);
	const journalEntriesStatus = useSelector((state) => state.journal.entries.status);
	const journalPromptsStatus = useSelector((state) => state.journal.prompts.status);

	// Create reference for components
	const isMounted = useRef(true);

	// Use hooks
	const dispatch = useDispatch();

	// Get available prompts
	const availablePrompts = useMemo(() => {
		if (journalEntries && journalPrompts) {
			const usedPromptIds = journalEntries.map(({ promptId }) => promptId).filter(Boolean);
			return journalPrompts.filter(({ id }) => !usedPromptIds.includes(id));
		}
		return [];
	}, [journalEntries, journalPrompts]);

	// Get answered entries
	const answeredEntries = useMemo(() => {
		if (journalEntries) {
			return journalEntries.filter(({ response }) => response);
		}
		return [];
	}, [journalEntries]);

	// Get unanswered entries
	const unansweredEntries = useMemo(() => {
		if (journalEntries) {
			return journalEntries.filter(({ response }) => !response);
		}
		return [];
	}, [journalEntries]);

	// Handle toggling expanded state
	const toggleExpandedState = (id) => {
		const expanded = { ...expandedState };
		expanded[id] = !expanded[id];
		setExpandedState(expanded);
	};

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial content loading is not complete
		if (journalEntriesStatus === 'idle') {

			// Fetch data state for page
			dispatch(fetchJournalEntries());
		}
	}, [journalEntriesStatus]);

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial content loading is not complete
		if (journalPromptsStatus === 'idle') {

			// Fetch data state for page
			dispatch(fetchJournalPrompts());
		}
	}, [journalPromptsStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render journal response
	const renderJournalResponse = (entry) => {
		if (user.role.primary === ROLES.PARENT) {
			return (
				<JournalResponseWidget
					entry={entry}
					isOpen={expandedState[entry.id]}
					handleNewResponse={(responseText) => {
						if (onResponseCreated) onResponseCreated(responseText);
					}}
					handleComplete={(responseAdded) => {
						toggleExpandedState(entry.id);
						if (onCompleteAction) onCompleteAction(responseAdded);
					}}
				/>
			);
		}
		return (
			<S.ResponseContainer>
				<Typography tag="p" variation="2" center={false}>{entry.response}</Typography>
			</S.ResponseContainer>
		);
	};

	// Render activity indicator
	const renderActivityIndicator = (entry) => {
		if (user.role.primary === ROLES.PARENT) {
			if (!entry.response) return <S.ActiveIndicator />;
		} else if (entry.response) return <S.ActiveIndicator />;
		return null;
	};

	// Render journal entries
	const renderJournalEntries = (entries) => entries.map((entry) => (
		<S.JournalEntry key={entry.id}>

			{/* Prompt Container */}
			<S.PromptContainer onClick={() => {
				if (user.role.primary !== ROLES.CHILD || entry.response) {
					toggleExpandedState(entry.id);
				}
			}}
			>
				{/* Activity Indicator */}
				{renderActivityIndicator(entry)}

				{/* Prompt */}
				<Typography tag="h5" weight="semibold">
					{(() => {
						const prompt = journalPrompts?.find(({ id }) => id === entry.promptId);
						return prompt ? prompt.prompt : entry.prompt;
					})()}
				</Typography>

				{/* Date */}
				<Typography tag="p" variation="2" weight="medium">{formatDateString(entry.updatedAt, 'MMM D [at] h:mm a')}</Typography>

			</S.PromptContainer>

			{/* Response */}
			{expandedState[entry.id] && renderJournalResponse(entry)}

		</S.JournalEntry>
	));

	// Render content
	const renderContent = () => {
		if (journalEntriesStatus === 'idle' || journalEntriesStatus === 'loading' || journalPromptsStatus === 'idle' || journalPromptsStatus === 'loading') {
			return <Spinner />;
		}
		if (journalEntriesStatus === 'failed' || journalPromptsStatus === 'failed') {
			return <ErrorComponent />;
		}
		return (
			<>
				{/* Header */}
				{!isJournalEntryOpen && (
					<S.Header>
						{user.role.primary === ROLES.PARENT
							? <Typography tag="h5" weight="semibold">Here you will find thoughts and questions sent to you by your child. Answer them to start a discussion!</Typography>
							: <Typography tag="h5" weight="semibold">Here you can send thoughts and questions to your parent. Add an entry to start a discussion!</Typography>}
					</S.Header>
				)}

				{/* Journal Entries */}
				{answeredEntries.length > 0 || unansweredEntries.length > 0 || isJournalEntryOpen ? (
					<S.JournalEntries>
						{isJournalEntryOpen ? (
							<JournalEntryWidget
								prompts={availablePrompts}
								handleNewEntry={(entryText) => {
									if (onEntryCreated) onEntryCreated(entryText);
								}}
								handleComplete={(entryAdded) => {
									setJournalEntryOpen(false);
									if (onCompleteAction) onCompleteAction(entryAdded);
								}}
							/>
						)
							: (
								<>
									{/* Entities */}
									{(user?.role?.primary === ROLES.CHILD ? answeredEntries : unansweredEntries).length > 0 && (
										<S.JournalEntrySection>
											<Typography tag="p" variation="1" weight="semibold">
												{user?.role?.primary === ROLES.CHILD ? 'Questions your parent has answered' : 'Questions from your child for you to answer'}
											</Typography>
											<S.JournalContainer>
												{renderJournalEntries(user?.role?.primary === ROLES.CHILD ? answeredEntries : unansweredEntries)}
											</S.JournalContainer>
										</S.JournalEntrySection>
									)}

									{/* Entities */}
									{(user?.role?.primary === ROLES.CHILD ? unansweredEntries : answeredEntries).length > 0 && (
										<S.JournalEntrySection>
											<Typography tag="p" variation="1" weight="semibold">
												{user?.role?.primary === ROLES.CHILD ? 'Questions you\'ve asked that are waiting on an answer from your parent' : 'Questions that you\'ve answered for your child'}
											</Typography>
											<S.JournalContainer>
												{renderJournalEntries(user?.role?.primary === ROLES.CHILD ? unansweredEntries : answeredEntries)}
											</S.JournalContainer>
										</S.JournalEntrySection>
									)}
								</>
							)}
					</S.JournalEntries>
				) : (
					<S.FullSize>
						{showEmptyState && (
							<S.EmptyComponent>
								{user.role.primary === ROLES.CHILD ? (
									<>
										<Typography tag="h4" weight="semibold">Your Journal Is Empty</Typography>
										<Typography tag="p">Ask a question to start a discussion with your parent!</Typography>
										<Button size="large" onClick={() => { setJournalEntryOpen(true); }}>
											<FontAwesomeIcon icon={['fal', 'pencil']} />
											<Typography tag="p" variation="2" weight="medium">Add an Entry</Typography>
										</Button>
									</>
								) : (
									<>
										<FontAwesomeIcon icon={['fal', 'book']} size="5x" />
										<Typography tag="h4" weight="semibold">Your Child&apos;s Journal Is Empty</Typography>
										<Typography tag="p">When your child writes their first journal entry, it will appear here.</Typography>
									</>
								)}
							</S.EmptyComponent>
						)}
					</S.FullSize>
				)}
			</>
		);
	};

	// Render component
	return (
		<S.Wrapper className={className}>

			{/* Title Widget */}
			<S.TitleWidget>
				<Typography tag="h4" weight="medium">Family Communication Journal</Typography>
				{user.role.primary === ROLES.CHILD && (
					<Button size="large" onClick={() => { setJournalEntryOpen(true); }}>
						<FontAwesomeIcon icon={['fal', 'pencil']} />
						<Typography tag="p" variation="2" weight="medium">Add an Entry</Typography>
					</Button>
				)}
			</S.TitleWidget>

			{/* Content */}
			<S.ContentContainer>
				{renderContent()}
			</S.ContentContainer>

		</S.Wrapper>
	);
};


/**
 * Configuration
 */

Journal.displayName = 'Journal';
Journal.propTypes = {
	className: PropTypes.string,
	onEntryCreated: PropTypes.func,
	onResponseCreated: PropTypes.func,
	onCompleteAction: PropTypes.func,
	showEmptyState: PropTypes.bool
};
Journal.defaultProps = {
	className: null,
	onEntryCreated: null,
	onResponseCreated: null,
	onCompleteAction: null,
	showEmptyState: true
};
