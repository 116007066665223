/*
 * File: colors.js
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 29, 2022 at 10:40 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: August 6, 2024 at 10:16 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Color Values
 */

export const ColorValues = {

	// Brand
	brandPrimaryBase: '#5F71E7',
	brandPrimaryDark: '#2a3578',
	brandPrimaryLighter: '#b8bff2',
	brandPrimaryLightest: '#ebedfa',
	brandSecondaryBase: '#58D9BA',
	brandSecondaryBaseDarker: '#4FB0AF',
	brandTertiaryBase: '#F7C549',
	brandQuaternaryBase: '#004E60',

	// Primary
	white: '#FFFFFF',
	dark: '#000000',
	backgroundDark: '#1A1A1A',

	// Neutral
	neutralButtonBase: '#747888',
	neutralButtonLighter: '#EEF0F6',
	neutralBackground: '#EFF1F3',
	neutralBackgroundDarker: '#39393B',
	neutralHighlight: '#E2E2E2',
	neutralHighlightDarker: '#505054',
	neutralForm: '#c5c9d6',
	neutralText: '#7F8496',

	// Success
	statusSuccessBase: '#25953E',
	statusSuccessLighter: '#EAFFEE',
	statusSuccessDarker: '#04290D',

	// Warning
	statusWarningBase: '#E1D037',
	statusWarningLighter: '#FFFDE7',
	statusWarningDarker: '#302B05',

	// Danger
	statusDangerBase: '#E13737',
	statusDangerLighter: '#FFEBEB',
	statusDangerDarker: '#360404',

	// Info
	statusInfoBase: '#1A4ED4',
	statusInfoLighter: '#E7EDFF',
	statusInfoDarker: '#0B1D4D',

	// Shadows
	navigationShadow: '0px 1px 1px rgb(0 0 0 / 15%)',
	cookieBarShadow: '0px -3px 10px rgba(0, 0, 0, 0.08)',
	elementShadow: '0px 4px 16px rgba(0 0 0 / 15%)',
	elementDarkShadow: '0px 4px 16px rgba(0 0 0 / 22%)',

	// Brands
	whatsAppBrand: '#25d366',
	facebookBrand: '#0075fa',
	twitterBrand: '#1da1f2',
	linkedinBrand: '#0a66c2',
	redditBrand: '#ff4500',
	pinterestBrand: '#e60019'
};


/**
 * Themes
 */

export const DarkTheme = {

	// Brand
	brandPrimaryBase: ColorValues.brandPrimaryBase,
	brandPrimaryDark: ColorValues.brandPrimaryDark,
	brandPrimaryLighter: ColorValues.brandPrimaryLighter,
	brandPrimaryLightest: ColorValues.brandPrimaryLightest,
	brandSecondaryBase: ColorValues.brandSecondaryBase,
	brandSecondaryBaseDarker: ColorValues.brandSecondaryBaseDarker,
	brandTertiaryBase: ColorValues.brandTertiaryBase,
	brandQuaternaryBase: ColorValues.brandQuaternaryBase,

	// Success
	statusSuccessBase: ColorValues.statusSuccessBase,
	statusSuccessLighter: ColorValues.statusSuccessDarker,

	// Warning
	statusWarningBase: ColorValues.statusWarningBase,
	statusWarningLighter: ColorValues.statusWarningDarker,

	// Danger
	statusDangerBase: ColorValues.statusDangerBase,
	statusDangerLighter: ColorValues.statusDangerDarker,

	// Info
	statusInfoBase: ColorValues.statusInfoBase,
	statusInfoLighter: ColorValues.statusInfoDarker,

	// Background
	primaryBackground: ColorValues.backgroundDark,
	secondaryBackground: ColorValues.neutralBackgroundDarker,
	cardBackground: ColorValues.backgroundDark,

	// Text
	primaryText: ColorValues.white,
	secondaryText: ColorValues.neutralText,

	// Button
	primaryButton: ColorValues.brandPrimaryBase,
	secondaryButton: ColorValues.neutralButtonBase,
	darkButton: ColorValues.backgroundDark,
	lightButton: ColorValues.neutralBackgroundDarker,

	// Decoration
	primaryDecoration: ColorValues.white,
	layoutBorder: ColorValues.neutralText,
	navigationBorder: ColorValues.neutralText,
	inactiveElement: ColorValues.neutralText,
	placeholderPrimary: ColorValues.neutralBackgroundDarker,
	placeholderHighlight: ColorValues.neutralHighlightDarker,

	// Gradient
	primaryGradientStart: 'rgba(0,0,0,0)',
	primaryGradientEnd: 'rgba(0,0,0,1)',

	// Input
	inputBackground: ColorValues.backgroundDark,
	inputSecondayBackground: ColorValues.neutralBackground,
	inputBorder: ColorValues.neutralText,
	inputFocusBorder: ColorValues.neutralButtonBase,
	inputPlaceholder: ColorValues.neutralText,
};

export const LightTheme = {

	// Brand
	brandPrimaryBase: ColorValues.brandPrimaryBase,
	brandPrimaryDark: ColorValues.brandPrimaryDark,
	brandPrimaryLighter: ColorValues.brandPrimaryLighter,
	brandPrimaryLightest: ColorValues.brandPrimaryLightest,
	brandSecondaryBase: ColorValues.brandSecondaryBase,
	brandSecondaryBaseDarker: ColorValues.brandSecondaryBaseDarker,
	brandTertiaryBase: ColorValues.brandTertiaryBase,
	brandQuaternaryBase: ColorValues.brandQuaternaryBase,

	// Success
	statusSuccessBase: ColorValues.statusSuccessBase,
	statusSuccessLighter: ColorValues.statusSuccessLighter,

	// Warning
	statusWarningBase: ColorValues.statusWarningBase,
	statusWarningLighter: ColorValues.statusWarningLighter,

	// Danger
	statusDangerBase: ColorValues.statusDangerBase,
	statusDangerLighter: ColorValues.statusDangerLighter,

	// Info
	statusInfoBase: ColorValues.statusInfoBase,
	statusInfoLighter: ColorValues.statusInfoLighter,

	// Background
	primaryBackground: ColorValues.white,
	secondaryBackground: ColorValues.neutralBackground,
	cardBackground: ColorValues.white,

	// Text
	primaryText: ColorValues.dark,
	secondaryText: ColorValues.neutralText,

	// Button
	primaryButton: ColorValues.brandPrimaryBase,
	secondaryButton: ColorValues.neutralButtonBase,
	darkButton: ColorValues.dark,
	lightButton: ColorValues.brandPrimaryLighter,

	// Decoration
	primaryDecoration: ColorValues.dark,
	layoutBorder: ColorValues.neutralForm,
	navigationBorder: 'transparent',
	inactiveElement: ColorValues.neutralText,
	placeholderPrimary: ColorValues.neutralBackground,
	placeholderHighlight: ColorValues.neutralHighlight,

	// Gradient
	primaryGradientStart: 'rgba(255,255,255,0)',
	primaryGradientEnd: 'rgba(255,255,255,1)',

	// Input
	inputBackground: ColorValues.white,
	inputSecondayBackground: ColorValues.neutralBackground,
	inputBorder: ColorValues.neutralForm,
	inputFocusBorder: ColorValues.neutralButtonBase,
	inputPlaceholder: ColorValues.neutralForm,
};
