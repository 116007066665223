/*
 * File: Answers.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 25, 2024 at 3:20 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import 'isomorphic-fetch';
import React, {
	useEffect, useMemo, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import {
	render, MARK_LINK, NODE_EMOJI, NODE_PARAGRAPH
} from 'storyblok-rich-text-react-renderer';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Store
import { fetchQA } from '../../../store/slices/content/content.slice';

// Components
import {
	Meta,
	AppNavigation,
	Spinner,
	ErrorComponent,
	Typography,
	Button,
	LocaleLink,
	Emoji,
	AskQuestionModal,
	Video
} from '../../../components';

// Constants
import { ROLES } from '../../../../Constants';

// Styles
import * as S from './Answers.styles';


/**
 * Configuration
 */

storyblokInit({
	accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
	apiOptions: {
		region: 'us'
	},
	use: [apiPlugin]
});

const richTextConfiguration = {
	markResolvers: {
		[MARK_LINK]: (children, { href, ...rest }) => <LocaleLink {...rest} to={href} target="_blank">{children}</LocaleLink>
	},
	nodeResolvers: {
		[NODE_EMOJI]: (children, { name, emoji }) => <Emoji label={name} symbol={emoji} size={1.2} />,
		[NODE_PARAGRAPH]: (children) => <Typography tag="p">{children}</Typography>
	},
	blokResolvers: {
		video: ({ /* eslint-disable-line react/prop-types */ video }) => {
			const { filename } = video;
			return (
				<Video url={filename} className="video" />
			);
		}
	}
};


/**
 * Component
 */

const Answers = ({ meta, locale, data }) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Create state handlers
	const [isAskQuestionModalOpen, setAskQuestionModalOpen] = useState(false);
	const [expandedState, setExpandedState] = useState({});

	// Get qa from hook
	const questions = useSelector((state) => state.content.qa.value);
	const questionsStatus = useSelector((state) => state.content.qa.status);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get Q&A section
	const sections = useMemo(() => {

		// Define comparison function
		const compare = (a, b) => {
			if (a.name < b.name) {
				return -1;
			} if (a.name > b.name) {
				return 1;
			} return 0;
		};

		// Build topics
		const topics = [];
		(questions || []).forEach((question) => {
			const index = topics.findIndex((topic) => topic.id === question.topic.id);
			if (index > -1) {
				topics[index].questions.push(question);
			} else {
				topics.push({
					...question.topic,
					questions: [question]
				});
			}
		});

		// Sort topics
		topics.sort(compare);

		// Return topics
		return topics;

	}, [questions]);

	// Use hooks
	const dispatch = useDispatch();

	// Create reference for components
	const isMounted = useRef(true);

	// Handle toggling expanded state
	const toggleExpandedState = (id) => {

		// Reset expanded state
		const expanded = {};
		(questions || []).forEach((question) => {
			expanded[question.id] = false;
		});

		// Update expanded state
		expanded[id] = !expandedState[id];
		setExpandedState(expanded);
	};

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (questionsStatus === 'idle') {

			// Fetch data state for page
			dispatch(fetchQA());
		}
	}, [questionsStatus]);

	// Handle actions on qa content change
	useEffect(() => {

		// Reset expanded state
		const expanded = {};
		(questions || []).forEach((question) => {
			expanded[question.id] = false;
		});
		setExpandedState(expanded);

	}, [questions]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render content
	const renderContent = () => {
		if (questionsStatus === 'idle' || questionsStatus === 'loading') {
			return <Spinner />;
		}
		if (questionsStatus === 'failed') {
			return <ErrorComponent />;
		}
		return (
			<>
				{/* Header */}
				<S.Header>
					{user.role.primary === ROLES.PARENT
						? (
							<Typography tag="h5" weight="semibold">
								Here are some questions asked by kids ages 10-13. Click on the question to get a short answer from our team. Click the “Ask a Question” button on the top of this page if you have your own question you want answered.
							</Typography>
						)
						: <Typography tag="h5" weight="semibold">Here some questions asked by kids like you:</Typography>}
				</S.Header>

				{/* Container */}
				<S.QuestionsSection>
					{sections.map(({ id, name, questions: items }) => (
						items.length > 0 ? (
							<S.QuestionsModule key={id}>
								<Typography tag="p" variation="1" weight="semibold">
									{name}
								</Typography>
								<S.QuestionsContainer>
									{items.map((question) => (
										<S.QuestionCard key={question.id}>
											<S.Question onClick={() => { toggleExpandedState(question.id); }}>
												<Typography center={false} tag="p" weight="semibold">{question.content}</Typography>
												<FontAwesomeIcon icon={expandedState[question.id] ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']} />
											</S.Question>
											{expandedState[question.id] && <S.Answer className="paragraphContent">{render(question.answer, richTextConfiguration)}</S.Answer>}
										</S.QuestionCard>
									))}
								</S.QuestionsContainer>
							</S.QuestionsModule>
						) : null
					)).filter(Boolean)}
				</S.QuestionsSection>
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation data={data}>
				<S.Wrapper left right>

					{/* Title Widget */}
					<S.TitleWidget>
						<Typography tag="h4" weight="medium">Answers to Kids&apos; Questions</Typography>
						<Button size="large" onClick={() => { setAskQuestionModalOpen(true); }}>
							<FontAwesomeIcon icon={['fal', 'plus']} />
							<Typography tag="p" variation="2" weight="medium">Ask a Question</Typography>
						</Button>
					</S.TitleWidget>

					{/* Content */}
					<S.ContentContainer>
						{renderContent()}
					</S.ContentContainer>

					{/* Decoration */}
					<S.Decoration />

				</S.Wrapper>
			</AppNavigation>

			{/* Ask Question Modal */}
			<AskQuestionModal isOpen={isAskQuestionModalOpen} handleClose={() => { setAskQuestionModalOpen(false); }} />
		</>
	);
};


/**
 * Configuration
 */

Answers.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
Answers.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default Answers;
