/*
 * File: Journal.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 6, 2024 at 12:25 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useRef
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Components
import {
	Meta,
	AppNavigation,
	JournalWidget
} from '../../../components';

// Styles
import * as S from './Journal.styles';


/**
 * Component
 */

const Journal = ({ meta, locale, data }) => {

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Create reference for components
	const isMounted = useRef(true);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation data={data}>
				<S.Wrapper left right>

					{/* Journal Widget */}
					<JournalWidget />

					{/* Decoration */}
					<S.Decoration />

				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

Journal.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
Journal.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default Journal;
