/*
 * File: TrialCompleteModal.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on February 4, 2022 at 4:30 PM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 26, 2024 at 9:33 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useState, useRef, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Howl } from 'howler';

// Components
import { Button } from '../Button';
import { Modal } from '../Modal';
import { Typography } from '../Typography';
import { ConfettiBurst } from '../ConfettiBurst';

// Constants
import { ROLES } from '../../../Constants';

// Styles
import * as S from './TrialCompleteModal.styles';


/**
 * Component
 */

export const TrialCompleteModal = ({
	className, isOpen, handleClose
}) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Use actions from hooks
	const navigate = useNavigate();

	// Create state handlers
	const [fireConfetti, setFireConfetti] = useState(false);

	// Create refs
	const confettiTargetRef = useRef();

	// Create sound hooks
	const fanfareSound = useMemo(() => new Howl({
		src: [`${process.env.CDN_URL}/public/assets/audio/fanfare.mp3`],
		html5: true,
		volume: 0.1
	}), []);

	// Handle actions on component load
	useEffect(() => {
		if (isOpen) {

			// Fire confetti
			setTimeout(() => {

				// Fire confetti
				setFireConfetti(true);

				// Reset confetti
				setTimeout(() => {
					setFireConfetti(false);
				}, 4000);

			}, 500);
		}
	}, [isOpen]);

	// Render component
	return (
		<Modal className={className} isOpen={isOpen} handleClose={handleClose} variant="medium" showClose clickOutsideClose useWrapper>
			<S.ModalInner>

				{/* Content Panel */}
				<S.ConfirmationPanel>

					{/* Icon */}
					<S.ConfirmationIconContainer>
						<FontAwesomeIcon icon={['fal', 'check-circle']} />
						<S.ConfettiTarget ref={confettiTargetRef} />
					</S.ConfirmationIconContainer>

					{/* Title */}
					<Typography tag="h3" weight="bold">You&apos;re a star!</Typography>

					{/* Instructions */}
					<Typography tag="p" variation="1" weight="medium">
						{user?.role?.primary === ROLES.PARENT ? (
							<>
								Congratulations, you&apos;re all done with activities for now! Head over to the Q&A page to explore answers to common questions asked by kids just like yours.
							</>
						) : (
							<>
								Congratulations, you’re all done with the activities! You’ve earned your reward! Let your parent know you made it to the end.
								<br />
								<br />
								While you wait on your reward, please check out the Q&A page to explore answers to common questions asked by kids just like you. You can also leave your own question for our team to answer here!
							</>
						)}
					</Typography>

					{/* Next Button */}
					<Button
						onClick={() => {
							handleClose();
							navigate('/dashboard/answers');
						}}
						size="large"
					>
						Explore Q&A
					</Button>

				</S.ConfirmationPanel>

				{/* Confetti */}
				{fireConfetti && (
					<ConfettiBurst
						fireAway={fireConfetti}
						targetRef={confettiTargetRef}
						force={0.4}
						duration={3000}
						particleCount={80}
						audioHook={fanfareSound}
					/>
				)}

			</S.ModalInner>

			{/* Background Decoration */}
			<S.ConfirmationBackground />

		</Modal>
	);
};


/**
 * Configuration
 */

TrialCompleteModal.displayName = 'TrialCompleteModal';
TrialCompleteModal.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func
};
TrialCompleteModal.defaultProps = {
	className: null,
	isOpen: false,
	handleClose: null
};
