/*
 * File: Resources.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: September 26, 2024 at 10:48 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, {
	useEffect, useMemo, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Store
import { fetchResources } from '../../../store/slices/content/content.slice';

// Components
import {
	Meta,
	AppNavigation,
	Spinner,
	ErrorComponent,
	Typography,
	LocaleLink,
	Button,
	SuggestResourceModal,
} from '../../../components';

// Constants
import { ROLES } from '../../../../Constants';

// Styles
import * as S from './Resources.styles';
import { updateFavoriteResources } from '../../../services/content';


/**
 * Component
 */

const Resources = ({ meta, locale, data }) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Create state handlers
	const [isSuggestResourceModalOpen, setSuggestResourceModalOpen] = useState(false);
	const [favoritedResources, setFavoritedResources] = useState((user?.favoritedResources || []).map(({ resourceId }) => resourceId));

	// Get resources from hook
	const resources = useSelector((state) => state.content.resources.value);
	const resourcesStatus = useSelector((state) => state.content.resources.status);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get terms section
	const sections = useMemo(() => {

		// Define comparison function
		const compare = (a, b) => {
			if (a.name < b.name) {
				return -1;
			} if (a.name > b.name) {
				return 1;
			} return 0;
		};

		// Build topics
		const topics = [];
		(resources || []).forEach((resource) => {
			const index = topics.findIndex((topic) => topic.id === resource.topic.id);
			if (index > -1) {
				topics[index].resources.push(resource);
			} else {
				topics.push({
					...resource.topic,
					resources: [resource]
				});
			}
		});

		// Sort topics
		topics.sort(compare);

		// Return topics
		return topics;

	}, [resources]);

	// Use hooks
	const dispatch = useDispatch();

	// Create reference for components
	const isMounted = useRef(true);

	// Handle update favorited resources
	const updateFavorites = async (resourceId) => {

		// Update resource ids
		let resourceIds = [...favoritedResources];
		if (resourceIds.includes(resourceId)) resourceIds = resourceIds.filter((r) => r !== resourceId);
		else resourceIds.push(resourceId);

		// Update state
		setFavoritedResources(resourceIds);

		// Update favorites
		try {
			await updateFavoriteResources({ resourceIds });
		} catch (e) {}
	};

	// Handle update favorited resources state
	useEffect(() => {

		// Update favorited resources
		setFavoritedResources((user?.favoritedResources || []).map(({ resourceId }) => resourceId));

	}, [user]);

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (resourcesStatus === 'idle') {

			// Fetch data state for page
			dispatch(fetchResources());
		}
	}, [resourcesStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render icon for type
	const typeIcon = (type) => {
		let icon = ['fal', 'link'];
		if (type === 'book') {
			icon = ['fal', 'books'];
		}
		return icon;
	};

	// Render content
	const renderContent = () => {
		if (resourcesStatus === 'idle' || resourcesStatus === 'loading') {
			return <Spinner />;
		}
		if (resourcesStatus === 'failed') {
			return <ErrorComponent />;
		}
		return (
			<>
				{/* Header */}
				<S.Header>
					<Typography tag="h5" weight="semibold">We have curated several resources for parents and kids. Check them out and let us know what you think! Please drop us a note here if you find a resource particularly helpful or if there’s a topic you think we’ve missed. You can also share your favorite resources that we can share with other families!</Typography>
				</S.Header>

				{/* Container */}
				<S.ResourcesContainer>
					{sections.map(({ id, name, resources: items }) => (
						items.length > 0 ? (
							<S.ResourcesModule key={id}>
								<Typography tag="p" variation="1" weight="semibold">
									{name}
								</Typography>
								<S.SectionCard>
									{items.map((resource) => (
										<S.Resource key={resource.id}>
											{resource.url || resource.type === 'url' ? (
												<LocaleLink to={resource.url || (resource.content.startsWith('http') ? resource.content : `https://${resource.content}`)} target="_blank">
													<S.ResourceFavorite onClick={(e) => {
														e.preventDefault();
														updateFavorites(resource.id);
													}}
													>
														<FontAwesomeIcon
															icon={favoritedResources.includes(resource.id) ? ['fas', 'star'] : ['fal', 'star']}
															className={favoritedResources.includes(resource.id) ? 'active' : undefined}
														/>
													</S.ResourceFavorite>
													<FontAwesomeIcon icon={typeIcon(resource.type)} />
													<Typography>
														<Typography weight="semibold">
															{resource.title}
														</Typography>
														{resource.content && (
															<>
																{' '}
																-
																{' '}
																{resource.content}
															</>
														)}
													</Typography>
												</LocaleLink>
											) : (
												<>
													<FontAwesomeIcon icon={typeIcon(resource.type)} />
													<Typography>
														{resource.content}
													</Typography>
												</>
											)}
										</S.Resource>
									))}
								</S.SectionCard>
							</S.ResourcesModule>
						) : null
					)).filter(Boolean)}
				</S.ResourcesContainer>

				{/* Parent Prompt */}
				<S.ParentPromptContainer>
					<Typography tag="h4" weight="semibold">
						More resources coming soon!
					</Typography>
					<Typography tag="p">
						If you have a resource to recommend to other parents, please click the “Suggest a Resource” button at the top of the page.
					</Typography>
				</S.ParentPromptContainer>
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation data={data}>
				<S.Wrapper left right>

					{/* Title Widget */}
					<S.TitleWidget>
						<Typography tag="h4" weight="medium">Resources</Typography>
						{user.role.primary === ROLES.PARENT && (
							<Button size="large" onClick={() => { setSuggestResourceModalOpen(true); }}>
								<FontAwesomeIcon icon={['fal', 'plus']} />
								<Typography tag="p" variation="2" weight="medium">Suggest a Resource</Typography>
							</Button>
						)}
					</S.TitleWidget>

					{/* Content */}
					<S.ContentContainer>
						{user.role.primary === ROLES.PARENT ? renderContent() : (
							<S.FullSize>
								<S.ChildCheckInContainer>
									<FontAwesomeIcon icon={['fal', 'books']} size="5x" />
									<Typography tag="h4" weight="semibold">
										For More Resources, Check In with Your Parent!
									</Typography>
									<Typography tag="p">
										Your parent can help you out with any questions you may have. Ask them for additional resources or
										{' '}
										<LocaleLink to="/dashboard/journal">ask a question in your journal</LocaleLink>
										.
									</Typography>
								</S.ChildCheckInContainer>
							</S.FullSize>
						)}
					</S.ContentContainer>

					{/* Decoration */}
					<S.Decoration />

				</S.Wrapper>
			</AppNavigation>

			{/* Suggest Resource Modal */}
			<SuggestResourceModal isOpen={isSuggestResourceModalOpen} handleClose={() => { setSuggestResourceModalOpen(false); }} />
		</>
	);
};


/**
 * Configuration
 */

Resources.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
Resources.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default Resources;
