/*
 * File: TermsList.jsx
 * Project: lets-talk-web
 *
 * Created by Brendan Michaelsen on January 30, 2022 at 12:11 AM
 * Copyright © 2022 Let's Talk. All rights reserved.
 *
 * Last Modified: July 12, 2024 at 3:46 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

// Utilities
import { createStateLocale } from '../../../utilities/locale';

// Store
import { fetchTerms } from '../../../store/slices/content/content.slice';

// Components
import {
	Meta,
	AppNavigation,
	Typography,
	Spinner,
	ErrorComponent,
	ToolTip,
} from '../../../components';

// Styles
import * as S from './TermsList.styles';
import { ROLES } from '../../../../Constants';


/**
 * Constants
 */

const SECTIONS = [
	{ start: 'a', end: 'f' },
	{ start: 'g', end: 'k' },
	{ start: 'l', end: 'p' },
	{ start: 'q', end: 'u' },
	{ start: 'v', end: 'z' }
];


/**
 * Component
 */

const TermsList = ({ meta, locale, data }) => {

	// Get current user from hook
	const user = useSelector((state) => state.user.value);

	// Get terms from hook
	const terms = useSelector((state) => state.content.terms.value);
	const termsStatus = useSelector((state) => state.content.terms.status);

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get terms section
	const sections = useMemo(() => SECTIONS.map(({ start, end }) => ({
		start,
		end,
		terms: (terms || []).map((term) => {
			const query = `[^${start.toLowerCase()}-${end.toLowerCase()}]`;
			const regex = new RegExp(query, 'g');
			return `${term.name.charAt(0)}`.toLowerCase().match(regex) ? false : term;
		}).filter(Boolean)
	})), [terms]);

	// Use hooks
	const dispatch = useDispatch();

	// Create reference for components
	const isMounted = useRef(true);

	// Handle actions on app component state change
	useEffect(() => {

		// Ensure initial page loading is not complete
		if (termsStatus === 'idle') {

			// Fetch data state for page
			dispatch(fetchTerms());
		}
	}, [termsStatus]);

	// Handle component initialization
	useEffect(() => {

		// Set state
		isMounted.current = true;

		// Handle actions on dismount
		return () => { isMounted.current = false; };

	}, []);

	// Render content
	const renderContent = () => {
		if (termsStatus === 'idle' || termsStatus === 'loading') {
			return <Spinner />;
		}
		if (termsStatus === 'failed') {
			return <ErrorComponent />;
		}
		return (
			<>

				{/* Header */}
				<S.Header>
					{user.role.primary === ROLES.PARENT
						? <Typography tag="h5" weight="semibold">Kids often hear terms related to puberty, anatomy, and sexuality, and they are not sure what the terms mean. Here&apos;s a list of terms with simple, kid-friendly definitions. Your child also has access to these terms and definitions. This could be a springboard for conversation.</Typography>
						: <Typography tag="h5" weight="semibold">Here are a list of terms to help refresh your memory:</Typography>}
				</S.Header>

				{/* Container */}
				<S.TermsContainer>
					{sections.map(({ start, end, terms: items }) => (
						items.length > 0 ? (
							<S.TermsModule key={start}>
								<Typography tag="p" variation="1" weight="semibold">
									{start.toUpperCase()}
									-
									{end.toUpperCase()}
								</Typography>
								<S.SectionCard>
									{items.map((term) => (
										<S.Term key={term.id}>
											<Typography>
												<Typography
													weight="semibold"
													className="visualInterest"
													style={{ marginRight: '6px' }}
												>
													{term.name}
													:
												</Typography>
												{' '}
												{term.definition}
											</Typography>
											{term.source && (
												<FontAwesomeIcon
													icon={['fal', 'link-simple']}
													data-tip={`Source: ${term.source.name}`}
													data-for="terms-tooltip"
												/>
											)}
										</S.Term>
									))}
								</S.SectionCard>
							</S.TermsModule>
						) : null
					)).filter(Boolean)}
				</S.TermsContainer>

				{/* Tooltip */}
				<ToolTip size="medium" id="terms-tooltip" />
			</>
		);
	};

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation data={data}>
				<S.Wrapper left right>

					{/* Title Widget */}
					<S.TitleWidget>
						<Typography tag="h4" weight="medium">Terms List</Typography>
					</S.TitleWidget>

					{/* Content */}
					<S.ContentContainer>
						{renderContent()}
					</S.ContentContainer>

					{/* Decoration */}
					<S.Decoration />

				</S.Wrapper>
			</AppNavigation>
		</>
	);
};


/**
 * Configuration
 */

TermsList.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
	data: PropTypes.shape(),
};
TermsList.defaultProps = {
	meta: {},
	locale: {},
	data: null
};


/**
 * Exports
 */

export default TermsList;
